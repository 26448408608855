
import Vue from "vue";

export default Vue.extend({
  name: "ReportPasswordValidationDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      password: "",
    };
  },
  methods: {
    async onConfirmation() {
      if (this.password) {
        this.$emit("onConfirmation", this.password);
        this.password = "";
      }
    },
  },
  watch: {},
});
