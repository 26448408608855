
import Vue from "vue";

export default Vue.extend({
  components: {},
  name: "ReportArchiveDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    submit() {
      if (!this.name) {
        return;
      }
      this.$emit("onSubmit", this.name);
      this.close();
    },
  },
});
